<template>
  <div id="app">
    <!-- <img src="@/assets/images/fast-Intro.gif" v-if="renderGif" class="full-screen-image"> -->
    <div id="loading-wrapper" v-if="!isMobile && videoLoader">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
    <Transition name="video-transition">
      <video ref="videoRef" id="introVideo" class="full-screen-video" disablePictureInPicture  v-if="renderGif && !isMobile" autoplay muted playsinline>
        <source src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/fast-Intro.mp4" type="video/mp4">
      </video>
    </Transition>
    <MobileHome v-if="isMobile && renderGif && isGifLoading"/>

    <div id="loading-wrapper" v-if="!renderGif ? isLoading : ''">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
    <Transition name="router-transition">
      <div v-if="!renderGif || isMobile">
        <router-view/>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  components:{
    MobileHome:()=>import('@/components/MobileHome.vue')
  },
  data() {
    return {
      renderGif: true,
      videoLoader: true
    }
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    },
    isMobile(){
      return window && window.innerWidth <= 991;
    },
    isGifLoading(){
      return this.$store.getters.isGifLoading
    }
  },
  mounted(){
    this.$store.dispatch('setLoading', false);
    this.$store.dispatch('setIsGifLoading', true);
    if(this.$route.query.ENAUCP_ID){
      localStorage.setItem('enrichr-augment-p1-cp-id', this.$route.query.ENAUCP_ID)
    }
    if(!this.isMobile){
      this.$refs.videoRef.onloadeddata=()=>{
        this.videoLoader = false;
      }
      this.$refs.videoRef.onended=()=>{
        this.renderGif = false;   
        setTimeout(() => {
          this.$store.dispatch('setIsGifLoading', false);
        }, 750);   
      }

    }
  }
}
</script>
<style scoped>
.mystyle {
  background-image: ();
}

.video-transition-leave-from{
  opacity: 1;
  filter: blur(0px);
  transform: scale(0);
}
.video-transition-leave-active{
  transition: all 1s cubic-bezier(.22,.42,.47,1.14);
}
.video-transition-leave-to{
  opacity: 0;
  filter: blur(60px);
  transform: scale(1.1);
}

.router-transition-enter-active{
  transition: all 1s cubic-bezier(.22,.42,.47,1.14);
  opacity: 1;
}
.router-transition-enter {
  opacity: 0;
}

.full-screen-image{
  height: 100vh;
  width: 100vw;
}
.full-screen-video{
  height: 100vh;
  width: 100vw;
  object-fit: fill;
  overflow-y: hidden;
  position: fixed;
  z-index: 1065;
}

@media (max-width:767px){
  .full-screen-video{
    object-fit: cover;
  }
}
</style>